import moment from 'moment'

// moment时间转为时间戳
export function timeTostamp (v, formatStr = 'YYYY-MM-DD') {
    if (v) {
      return parseInt(
        moment(moment(v || new Date()).format(formatStr)).valueOf() / 1000
      )
    }
    return ''
  }
  // 时间戳转为时间字符串
  export function stampToTime (v, formatStr = 'YYYY-MM-DD') {
    if (!v) {
      return ''
    }
    if (v && v === '0') {
      return ''
    }
    return moment((v || 0) * 1000).format(formatStr)
  }
let key = "token";
export const settoken = (token) => {
    return sessionStorage.setItem(key, token)
}
export const gettoken = () => {
    return sessionStorage.getItem(key)
}
export const removetoken = () => {
    return sessionStorage.removeItem(key)
}
let itmeid = "organ_id"
export const setid = (id) => {
    return sessionStorage.setItem(itmeid, id)
}
export const getid = () => {
    return sessionStorage.getItem(itmeid)
}
export const removeed = () => {
    return sessionStorage.removeItem(itmeid)
}
let itmetype = "type"
export const settype = (type) => {
    return sessionStorage.setItem(itmetype, type)
}
export const gettype = () => {
    return sessionStorage.getItem(itmetype)
}
export const removetype = () => {
    return sessionStorage.removeItem(itmetype)
}

let teachername = "teachername"
export const setname = (name) => {
    return sessionStorage.setItem(teachername, name)
}
export const getname = () => {
    return sessionStorage.getItem(teachername)
}
export const removename = () => {
    return sessionStorage.removeItem(teachername)
}

let labelId = "label_id"
export const setLabelId = (label_id) => {
    return sessionStorage.setItem(labelId, label_id)
}
export const getLabelId = () => {
    return sessionStorage.getItem(labelId)
}
export const removeLabelId = () => {
    return sessionStorage.removeItem(labelId)
}












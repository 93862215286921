import Vue from 'vue'
import {
    NavBar, Field, Form, Button, Tabbar, TabbarItem, Icon, Tab, Tabs, Cell,
    CellGroup, List, PullRefresh, ActionSheet, Notify, Popup, Tag, Col, Row, Search, Toast,
    DropdownMenu, DropdownItem, DatetimePicker, ContactCard, Dialog, RadioGroup, Radio, Calendar,
    Checkbox, CheckboxGroup, Swipe, SwipeItem, Empty, ImagePreview, Pagination, Popover, Picker, Overlay
} from 'vant';

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(NavBar);
Vue.use(Field);
Vue.use(Form);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(ActionSheet);
Vue.use(Notify);
Vue.use(Popup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Search);
Vue.use(Toast);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(DatetimePicker);
Vue.use(ContactCard);
Vue.use(Dialog);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Calendar);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Empty);
Vue.use(ImagePreview);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Picker);
Vue.use(Overlay);
Vue.prototype.$vantnotify = Notify
// Vue.prototype.$toast = Toast
// Vue.prototype.$dialog = Dialog








































import Vue from 'vue'
import axios from "axios"
// import router from "../router/index"
import { Notify } from "vant"
import { gettoken, getid, gettype } from "./token"
import router from "@/router/index"
import isPlainObject from 'lodash/isPlainObject'
import qs from "qs";
const sysConfig = require("./baseurl.js");
Vue.use(Notify);
// console.log(process.env.NODE_ENV);
const requests = axios.create({
  baseURL: sysConfig.baseUrl,
  // baseURL: "https://homeapi.xingxiwang.com", // 线上接口
  // baseURL: "https://hapi.must520.com", // 测试接口
  // baseURL: "/all",
  timeout: 15000,
})
// requests.interceptors.request.use(config => {
//     // config.headers.Authorization=`Bearer ${gettoken()}`
//     console.log(config)
//     return config
// })

// requests.interceptors.response.use(res => {
//     return res.data
// }, error => {
//     if (error.response.status == 401) {
//         Notify({ type: "warning", message: "身份信息已失效" });
//         router.replace("/login")
//     }

//     return Promise.reject(new Error("faile"))
// })

// 定义存放已发送但未响应的请求
const requestMap = new Map();
// 获取请求的唯一标识
const getRequestIdentify = (config, isReuest = false) => {
  // 由于不同项目的实现方式略有不同，url、params和data的格式可能略有不同，使用该方法时要根据实际情况进行调试修改
  let url = config.url
  if (isReuest) {
    url = url.startsWith('/') ? sysConfig.baseUrl + url : sysConfig.baseUrl + '/' + url;
    if (config.params && config.params._t) {
      delete config.params._t
    }
  }
  return config.method === 'get' ? 
    encodeURI(url + JSON.stringify(config.params)) : 
    encodeURI(url + (typeof config.data === 'string' ? 
    config.data : 
    JSON.stringify(config.data)))
}

/**
 * 请求拦截
 */
requests.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  config.headers.token = gettoken() || ''
  config.headers.organid = getid() || ''
  config.headers.type = gettype()
  config.headers.Accept = '*/*'
  // config.params = {
  //   // _t: new Date().getTime()
  // }
  // 默认参数
  // const defaults = {}
  // 防止缓存,GET 请求默认带_t参数
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      ...{ _t: new Date().getTime() }
    }
  }

  if (isPlainObject(config.data)) {
    config.data = qs.stringify(config.data)
  }

  const requestData = getRequestIdentify(config, true);
  if (requestMap.has(requestData)) {
    // map中存在该请求，不再重复发起
    return Promise.reject(new Error('请勿重复发起请求'))
  } else if (!config.canReapeat) {
    // 通过在请求中设置 canReapeat 参数，允许特殊接口的重复提交
    // 若map中没有该请求，则将请求存入map，并发起请求
    requestMap.set(requestData, true)
  }


  // console.log(config);
  return config
}, err => {
  return err
})
/**
 * 响应拦截
 */
requests.interceptors.response.use(response => {
  if(response.data.code * 1 === 9990){
    Notify(response.data.message);
    router.replace({
      name: 'login'
    })
  }
  const res = {
    code: response.data.code * 1,
    msg: response.data.message,
    data: response.data.data['error-info']
  }

  let url = getRequestIdentify(response.config)
  const requestData = url.startsWith('/') ? sysConfig.baseUrl + url : sysConfig.baseUrl + '/' + url;
  // 请求获得响应后，将该请求从map中移除，以使后面的请求正常发送。
  if (requestMap.has(requestData)) {
    requestMap.delete(requestData)
  }


  return res
}, err => {
  return err
})

export default requests







































































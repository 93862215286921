if (process.env.NODE_ENV !== 'production') {
  module.exports = {
    baseUrl: 'https://hapi.must520.com',
    cdn: {
      host: 'https://cdn.xingxiwang.com/'
    }

  }
} else {
  module.exports = {
    baseUrl: 'https://homeapi.xingxiwang.com',
    cdn: {
      host: 'https://cdn.xingxiwang.com/'
    }
  }
}

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
const router = new VueRouter({
    routes: [
        {
            path: "/",
            redirect: "/login/"
        },
        {
            path: "/login",
            name: 'login',
            component: () => import("../view/login/index.vue")
        },
        {
            path: "/AssessmentRecords",
            name: 'AssessmentRecords',
            component: () => import("../view/AssessmentRecords/index.vue")
        },

        {
            path: "/startpingu",
            name: 'startpingu',
            component: () => import("../view/AssessmentRecords/components/startpingu.vue")
        },
        {
            path: "/addtable",
            name: 'addtable',
            component: () => import("../view/AssessmentRecords/components/addtable.vue")
        },
        {
            path: "/addTruefalse",
            name: 'addTruefalse',
            component: () => import("../view/AssessmentRecords/components/addTruefalse.vue")
        },
        {
            path: "/addexercises",
            name: 'addexercises',
            component: () => import("../view/AssessmentRecords/components/addexercises.vue")
        },
        {
            path: "/choosepicture",
            name: 'choosepicture',
            component: () => import("../view/AssessmentRecords/components/choosepicture.vue")
        },
        {
            path: "/index",
            name: 'index',
            component: () => import("../view/index.vue")
        },
        {
            path: "/mystu",
            name: 'mystu',
            component: () => import("../view/mystu/index.vue")
        },
        {
            path: "/stuDetail",
            name: 'stuDetail',
            component: () => import("../view/mystu/stuDetail.vue")
        },
        {
            path: "/courseDetail",
            name: 'courseDetail',
            component: () => import("../view/mystu/courseDetail.vue")
        },
        {
            path: "/startClass",
            name: 'startClass',
            component: () => import("../view/startClass/index.vue")
        },
        {
            path: "/submitPreview",
            name: 'submitPreview',
            component: () => import("../view/submitPreview/index.vue")
        },
        {
            path: "/oralAssessment",
            name: 'oralAssessment',
            component: () => import("../view/oralAssessment/index.vue")
        },
        {
            path: "/oralSnag",
            name: 'oralSnag',
            component: () => import("../view/oralAssessment/snag.vue")
        },
        {
            path: "/diagnosis",
            name: 'diagnosis',
            component: () => import("../view/oralAssessment/diagnosis.vue")
        },
        {
            path: "/criterion",
            name: 'criterion',
            component: () => import("../view/oralAssessment/criterion.vue")
        },
        {
            path: "/oralTopic",
            name: 'oralTopic',
            component: () => import("../view/oralAssessment/topic.vue"),
            key: true
        },
    ]
})

export default router


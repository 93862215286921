import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import 'amfe-flexible'
import "./plugin/index"
import '/src/styles/iconfont.css'
import  store from './store'
import './plugin/rem'
import http from "@/api/request";
import { stampToTime, timeTostamp } from '@/plugin/utils'
Vue.prototype.$http = http
Vue.prototype.$stampToTime = stampToTime
Vue.prototype.$timeTostamp = timeTostamp
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
